<template>
  <div>
    <div class="container">
      <el-form ref="form" :model="queryInfo" label-width="120px">
        <el-card>
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="工具类型:" prop="">
                <el-radio-group v-model="queryInfo.evaluationType" @change="changeSearchangeSearch">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="1">企业数智化转型诊断评估 </el-radio-button>
                  <el-radio-button label="2">企业数智化转型政策申报 </el-radio-button>
                  <el-radio-button label="3">调查问卷工具</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <!-- <el-form-item label="诊断记录:" prop="">
                <el-radio-group v-model="queryInfo.diagnoseState" @change="search">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="1">已诊断 </el-radio-button>
                  <el-radio-button label="0">未诊断 </el-radio-button>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item label="填写状态:" prop="">
                <el-radio-group v-model="queryInfo.submitState" @change="changeSearchangeSearch">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="0">已保存 </el-radio-button>
                  <el-radio-button label="1">已提交 </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-card>
      </el-form>
      <el-card style="margin-top: 20px">
        <div class="input-with">
          <el-button type="primary" @click="diagnosisclick">进入诊断工具</el-button>
          <el-button class="export_btn" @click="exportExcel">批量导出</el-button>
          <el-input
            placeholder="请输入企业名称"
            v-model="queryInfo.companyName"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="changeSearchangeSearch">搜索</el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          ref="multipleTable"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
        >
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column label="编号" align="center">
            <template v-slot="{ row }">
              <span>{{ 'ZDJL' + row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" align="center" min-width="200">
            <template v-slot="{ row }">
              <span>{{ row.companyName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提交保存时间" prop="createTime" align="center" width="140" sortable>
            <template v-slot="{ row }">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column label="工具类型" align="center" min-width="140">
            <template v-slot="{ row }">
            <span>
              {{ getEvaluationTypeTxt(row.evaluationType) }}
            </span>
            </template>
          </el-table-column>
          <el-table-column
            label="工具名称"
            prop="questionnaireName"
            align="center"
            min-width="140"
          ></el-table-column>
          <el-table-column label="填写状态" prop="companyFullName" align="center" width="80">
            <template v-slot="{ row }">
              <span v-if="row.submitState == 0">已保存</span>
              <span style="color: #4e93fb" v-if="row.submitState == 1">已提交</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="诊断状态" prop="content" align="center" min-width="100">
            <template v-slot="{ row }">
              <span style="color: #ff7d18" v-if="row.diagnoseState == 0">未诊断</span>
              <span style="color: #4e93fb" v-if="row.diagnoseState == 1">已诊断</span>
            </template>
          </el-table-column> -->
          <el-table-column label="姓名" prop="name" align="center" min-width="100"></el-table-column>
          <el-table-column label="职位" prop="position" align="center" width="80"></el-table-column>
          <el-table-column label="手机号" align="center" min-width="120">
            <template v-slot="{ row }">
              <span>{{ row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作人"
            sortable
            prop="updateName"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="操作时间" sortable prop="updateTime" align="center" width="140">
            <template v-slot="{ row }">
              <div>{{ row.updateTime | date }}</div>
              <div>{{ row.updateTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            min-width="100"
          >
            <template slot-scope="scope">
              <!-- <el-button
                v-if="scope.row.submitState == 1 && scope.row.diagnoseState == 0"
                size="mini"
                type="primary"
                @click="torecord(scope.row)"
                >去诊断</el-button
              >
              <el-button v-if="scope.row.diagnoseState == 1" size="mini" type="info"
                >已诊断</el-button
              > -->
              <el-button
                v-if="scope.row.submitState == 0"
                type="warning"
                size="mini"
                @click="toToolPage(scope.row)"
              >去填写</el-button
              >
              <el-button style="color: #448aff" type="text" @click="torecord(scope.row)"
              >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { userDataExport } from '@/api/user'
import { getDiagnosticTools, exportExcel } from '@/api/diagnosis'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  reportScore: 0,
  pageNum: 1,
  pageSize: 10,
  endTime: null, //结束时间
  companyName: '', //公司名称
  diagnoseState: '', //诊断状态（0：未诊断，1：已诊断）
  id: null, //诊断书ID
  submitState: '', //提交状态（0：保存，1：提交）
  evaluationType: '', //工具类型 1:企业数智化转型诊断评估,2:企业数智化转型政策申报
  userId: null, //用户id
  questionnaireId: null //
})
export default {
  name: 'Diagnosisrecord',
  mixins: [pagination],
  data() {
    return {
      ids: [], // 选中的id
      rows: [], //选中的行
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      loading: false, // 遮罩层
      total: 0 // 总条数
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await getDiagnosticTools(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.loading = false
      }
    },
    getRowKey(row) {
      return row.id
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      console.log(this.ids)
      // this.single = selection.length !== 1
      // this.multiple = !selection.length
    },
    //批量导出
    async exportExcel() {
      this.loading = true
      // userDataExport exportExcel
      const res = await exportExcel(this.ids)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '.'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '.'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `【智参】诊断工具用户填写信息表格${nowtime}.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        this.search()
        this.$refs.multipleTable.clearSelection()
      } else {
        this.loading = false
        this.$message.error('下载失败!')
      }
    },
    // 获取诊断工具类型名称
    getEvaluationTypeTxt(val) {
      if (val == '1') {
        return '企业数智化转型诊断评估工具'
      } else if (val == '2') {
        return '企业数智化转型政策申报工具'
      } else {
        return '调查问卷工具'
      }
    },
    //进入诊断详情
    torecord(row) {
      this.$router.push({
        path: '/diagnosis/recordinfo',
        query: {
          id: row.webId,
          diagnoseId: row.id,
          questionnaireId: row.questionnaireId
          // isSelect: row.isSelect  // 是否查询分析报告-已弃用
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.$refs.multipleTable.clearSelection()
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    //跳转诊断根据
    diagnosisclick() {
      var url = 'https://tool.elitegomedia.com/information'

      // window.location = url;
      window.open(url, '_blank')
    },
    // 进入诊断工具H5页面
    toToolPage(row) {
      var url = `https://tool.elitegomedia.com/information/policytools?webId=${row.webId}&id=${
        row.questionnaireId
      }&flag=${1}`

      // window.location = url;
      window.open(url, '_blank')
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: calc(100vh - 150px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  display: flex;
  ::v-deep .export_btn {
    color: #333;
    background-color: #fff;
    border: #f0f0f0 1px solid;
  }
  ::v-deep .export_btn:hover {
    // opacity: 0.8;
    color: #448aff;
    border: #448aff 1px solid;
  }
  .input-with-select {
    width: 400px;
    margin-left: auto;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>
